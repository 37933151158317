<template>
  <div class="modal" id="modal-preloader" tabindex="-1" role="dialog" aria-labelledby="modal-preloader" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content pay-modal-animate__wrapp">
        <div class="modal-body">
          <div class="pay-modal-animate">
            <div class="pay-modal-animate__heading">{{ dataTitle }}</div>
            <div class="pay-modal-animate__descr">{{ dataSubTitle }}</div>
            <div class="results_anime">
              <div class="results_anime_circles">
                <div class="waiting_animation">
                  <div class="background">
                    <div class="loader loader-left"></div>
                    <div class="loader loader-right"></div>
                  </div>
                </div>
              </div>
              <div class="results_anime_percent">
                <span class="results_anime_percent-text"></span>
              </div>
              <div class="results_anime_line">
                <span class="results_anime_line-scale" :style="`width:`+width+`%;`"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [
  ],
  data: function () {
    return {
      width:0,
      dataTitle:"",
      dataSubTitle:""
    }
  },
  created() {
    this.$root.$on('showAnimateModalPreloader', ({ dataTitle, dataSubTitle }) => {
      this.dataTitle = dataTitle;
      this.dataSubTitle = dataSubTitle;
      this.showAnimateModal();
    });
    this.$root.$on('hideAnimateModalPreloader', () => {
      this.hideAnimateModal();
    });
  },
  beforeDestroy() {

  },
  mounted() {
  },
  methods: {
    hideAnimateModal(){
      $('#modal-preloader').modal('hide');
      $(".modal-backdrop").css('display','none')
    },
    showAnimateModal() {
      $('#modal-preloader').modal('show');
      this.width = 0;
      const intervalId = setInterval(() => {
        if (this.width >= 99) {
          clearInterval(intervalId);
        } else {
          this.width += Math.floor((Math.random() * 10));
        }
      }, 200);
    },
  }
}
</script>
<style lang="scss">
.modal{

}
</style>
