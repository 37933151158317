<template>
  <div class="modal custom-alert" :id="'custom-alert-'+popupId" tabindex="-1" role="dialog" aria-labelledby="custom-alert" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-md" role="document">
      <div class="modal-content pay-modal-animate__wrapp">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-header flex-center">
            <div class="modal-title">Увага!</div>
          </div>
          <div class="modal-container__content orders">
            <div class="scroll-content">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="info-block"  v-html="message">
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-6">
                <button @click="closeAlert" class="btn btn-orange">Ок</button>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message', "popupId"],
  methods: {
    closeAlert() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.custom-alert{
  z-index: 999999;
}
.custom-alert .modal-content {
  width: 500px;
  margin: 0 auto;
  padding: 15px;
}
button.btn{
  display: block;
  margin: 0 auto;
  width: 150px;
}
.modal-body{
  text-align: justify;
}
.modal-header.flex-center{
  justify-content: center;
}
.modal-title{
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}
</style>
